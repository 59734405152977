import {
    collection,
    doc,
    query,
    setDoc,
    updateDoc,
    where,
  } from "firebase/firestore";
  import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
  import { nanoid } from "nanoid";
  import { AEDLocation } from "../../types/locations"

export default function useUserInfo() {
    const DB = useFirestore();
    const allUserInfo = collection(DB, "userInfo");
    const { status: userInfoStatus, data: userInfoData} = useUser();

    const userInfoQuery = query(
        allUserInfo,
        where("uid", "==", (userInfoStatus !== "loading" && userInfoData) ? userInfoData.uid : 0)
    );

    const { status: userInfoQueryStatus, data: userInfoQueryData } =
    useFirestoreCollectionData(userInfoQuery);

    const userExistsInDB = userInfoQueryData && userInfoQueryData.length !== 0;

    const updateUserInfo = async () => {
        if (!userExistsInDB && userInfoQueryStatus !== "loading") {
            const newUser = {
                id: userInfoData.uid,
                email: userInfoData.email,
                isAdmin: false
            };
            
            await setDoc(doc(DB, "userInfo", userInfoData.uid), newUser);
        }
    };

    return {
        updateUserInfo,
        isAdmin: userExistsInDB
    };
}