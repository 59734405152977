import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import { useEffect, useRef, useState } from 'react';
import {
  AEDLocation,
  AEDLocationWithLatLng,
  ParsedAddress,
} from '../../../types/locations';
import CONFIG from '../../config/maps.json';
import useAuthentication from '../../hooks/useAuthentication';
import {
  DEFAULT_LOCATION,
  useLocations,
} from '../../hooks/useLocations';
import LocationCard from './components/LocationCard/LocationCard';
import { LocationModal } from './components/LocationModal/LocationModal';
import './components/LocationModal/LocationModal.scss';
import './Locations.scss';
// import Places from "google-places-web";

export default function Locations() {
  const [showAddLocationModal, setShowAddLocationModal] =
    useState(false);
  const [
    showModifyLocationModal,
    setShowModifyLocationModal,
  ] = useState(false);
  const {
    data: locations,
    status: locationStatus,
    addLocation,
    updateLocation,
  } = useLocations();
  const [filteredLocations, setFilteredLocations] =
    useState<AEDLocation[]>([]);
  const [filterValue, setFilterValue] = useState('');
  const [archiveCandiate, setArchiveCandiate] = useState<{
    id: string;
    name: string;
  }>({ id: '', name: '' });
  const [
    showArchiveWarningModal,
    setShowArchiveWarningModal,
  ] = useState(false);
  const [activeLocation, setActiveLocation] = useState(
    DEFAULT_LOCATION
  );
  const { signOut } = useAuthentication();
  const [service, setService] =
    useState<google.maps.places.PlacesService>();
  const [locationsWithLatLng, setLocationsWithLatLng] =
    useState<AEDLocationWithLatLng[]>([]);

  const mapRef = useRef();

  useEffect(() => {
    if (locations) {
      if (filterValue.length > 0) {
        setFilteredLocations(
          [...locations].filter(
            (location) =>
              location.name
                .toLowerCase()
                .includes(filterValue) ||
              location.address
                .toLowerCase()
                .includes(filterValue) ||
              location.phoneNumber
                .toLowerCase()
                .includes(filterValue)
          )
        );
      } else {
        setFilteredLocations(locations);
      }
    } else {
      setFilteredLocations([]);
    }
  }, [filterValue, locations.toString()]);

  useEffect(() => {
    setActiveLocation(
      locations.length > 0 ? locations[0] : DEFAULT_LOCATION
    );

    setTimeout(() => {
      const map = new google.maps.Map(
        document.getElementById('map') as HTMLElement,
        {
          center: { lat: 28.010833, lng: -81.742578 },
          zoom: 17,
          mapId: '8d193001f940fde3',
        } as google.maps.MapOptions
      );
      setService(new google.maps.places.PlacesService(map));
    }, 500);
  }, []);

  const filteredPlacesWithLatLngs = (
    locations: AEDLocation[],
    callback: (places: AEDLocationWithLatLng[]) => void
  ) => {
    let places: AEDLocationWithLatLng[] = [];

    if (service) {
      locations.map((location) => {
        service.getDetails(
          {
            placeId: (
              JSON.parse(location.address) as ParsedAddress
            ).value.place_id,
            fields: ['geometry'],
          },
          (res) => {
            const { lat, lng } = res.geometry.location;
            let AEDLocationLatLng = {
              ...location,
              lat: lat(),
              lng: lng(),
            } as AEDLocationWithLatLng;
            places.push(AEDLocationLatLng);
            if (places.length === locations.length) {
              callback(places);
            }
          }
        );
      });
    }
  };

  useEffect(() => {
    filteredPlacesWithLatLngs(locations, (places) => {
      setLocationsWithLatLng(places);
    });
  }, [locations]);

  if (locationStatus === 'loading' || !filteredLocations) {
    // LOADING SPINNER
    return <></>;
  }

  const Marker = ({
    lat,
    lng,
  }: {
    lat: number;
    lng: number;
  }) => {
    return (
      <div
        style={{
          width: 20,
          height: 20,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          boxShadow: '0 0 10px rgba(255, 50, 50, 0.9)',
          borderRadius: 10,
        }}
      ></div>
    );
  };

  return (
    <div id='wrapper'>
      <div id='header-row'>
        <div>
          <img
            src='../../assets/images/logo.png'
            id='logo'
          />
        </div>

        <div>
          <button
            id='sign-out-button'
            onClick={() => {
              signOut();
            }}
            title='Sign out'
          >
            Sign Out
          </button>

          <button
            id='add-location-button'
            onClick={() => {
              setShowAddLocationModal(true);
            }}
          >
            Add Location
          </button>
        </div>
      </div>

      <div id='map'></div>

      <LocationModal
        show={showAddLocationModal}
        onSubmit={(location: AEDLocation) => {
          if (
            location.name.length > 0 &&
            location.address.length > 0 &&
            location.phoneNumber.length > 0
          ) {
            addLocation({
              name: location.name,
              address: location.address,
              phoneNumber: location.phoneNumber,
              archived: false,
            });
          }
        }}
        onClose={() => {
          setShowAddLocationModal(false);
        }}
      />

      <LocationModal
        show={showModifyLocationModal}
        onSubmit={(location: AEDLocation) => {
          updateLocation(location.id, location);
        }}
        initialLocation={activeLocation}
        onClose={() => {
          setShowModifyLocationModal(false);
        }}
      />

      <div id='page-contents'>
        <div id='map-wrapper'>
          <GoogleMapReact
            bootstrapURLKeys={{ key: CONFIG.MAPS_API_KEY }}
            center={
              locationsWithLatLng.length
                ? {
                    lat: locationsWithLatLng[0].lat,
                    lng: locationsWithLatLng[0].lng,
                  }
                : {
                    lat: 28.009565831256996,
                    lng: -81.73198178784496,
                  }
            }
            zoom={10}
            yesIWantToUseGoogleMapApiInternals
            // onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            {locationsWithLatLng.map((location) => (
              <Marker
                lat={location.lat}
                lng={location.lng}
                key={location.id}
              />
            ))}
          </GoogleMapReact>
        </div>
        <div id='location-wrapper'>
          <input
            type='text'
            placeholder='Filter locations ...'
            onChange={(e) => setFilterValue(e.target.value)}
            id='location-filter'
          />
          {filteredLocations.map((location) => {
            return (
              <LocationCard
                location={location}
                key={location.id}
                setActive={setActiveLocation}
                active={activeLocation.id === location.id}
                onShowModifyModal={() => {
                  setActiveLocation(location);
                  setShowModifyLocationModal(true);
                }}
                onShowArchiveWarningModal={(
                  locationInfo
                ) => {
                  setArchiveCandiate(locationInfo);
                  setShowArchiveWarningModal(true);
                }}
              />
            );
          })}
        </div>
      </div>
      {showArchiveWarningModal && (
        <>
          <div className='modal'>
            <div className='modal-header'>
              Are you sure you want to archive{' '}
              <strong>{archiveCandiate.name.trim()}</strong>
              ?
            </div>
            <div className='modal-actions'>
              <div>
                <button
                  className='modal-action-button neutral'
                  title='Cancel this action'
                  onClick={() => {
                    setShowArchiveWarningModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className='modal-action-button archive right'
                  title='Archive this location'
                  onClick={() => {
                    updateLocation(archiveCandiate.id, {
                      archived: true,
                    });
                    setShowArchiveWarningModal(false);
                  }}
                >
                  Yes. Archive this location.
                </button>
              </div>
            </div>
          </div>
          <div className='gauze'></div>
        </>
      )}
    </div>
  );
}
