import {
  collection,
  doc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import {
  useFirestore,
  useFirestoreCollectionData,
  useUser,
} from 'reactfire';
import { nanoid } from 'nanoid';
import { AEDLocation } from '../../types/locations';

export const DEFAULT_LOCATION: AEDLocation = {
  id: nanoid(),
  name: '',
  address: '',
  phoneNumber: '',
  archived: false,
};

export function useLocations() {
  const DB = useFirestore();
  const allLocations = collection(DB, 'locations');
  const { data, status } =
    useFirestoreCollectionData(allLocations);

  const addLocation = async (newLocation: AEDLocation) => {
    const locationUUID = nanoid();
    const newLocationWithUUID = {
      id: locationUUID,
      archived: false,
      createdOn: new Date().toString(),
      ...newLocation,
    };

    await setDoc(
      doc(DB, 'locations', locationUUID),
      newLocationWithUUID
    );
  };

  const updateLocation = async (
    locationId: string,
    newConfig: any
  ) => {
    const existingLocationRef = doc(
      DB,
      'locations',
      locationId
    );
    await updateDoc(existingLocationRef, newConfig);
  };

  const filteredData = (
    (data || []) as AEDLocation[]
  ).filter((location) => !location.archived);

  return {
    data: filteredData,
    status,
    addLocation,
    updateLocation,
  };
}
