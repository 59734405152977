import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut
} from "firebase/auth";
import { useAuth } from "reactfire";

export default function useAuthentication() {
  const auth = useAuth();

  const signUp = async (email: string, password: string) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
    } catch (err) {
      console.warn(err.message);
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.warn(err.message);
    }
  };

  const signOut = () => {
    firebaseSignOut(auth);
  }

  return {
    signUp,
    signIn,
    signOut
  };
}
