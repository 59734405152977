import clsx from "clsx";
import { useState } from "react";
import useAuthentication from "../../hooks/useAuthentication";
import "./SignIn.scss";

enum MODES {
  SIGN_IN,
  SIGN_UP,
}

export default function SignIn() {
  const [mode, setMode] = useState(MODES.SIGN_IN);
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");
  const [signUpEmail, setSignUpEmail] = useState("");
  const [signUpPassword, setSignUpPassword] = useState("");

  const { signUp, signIn } = useAuthentication();

  return (
    <div
      className="sign-in-page-wrapper"
      style={{ backgroundImage: "url(assets/images/chain-of-lakes.png" }}
    >
      <div className="box">
        {mode === MODES.SIGN_IN ? (
          <>
            <div className="header">SIGN IN</div>
            <div className="form">
              <label className="form-field-label">EMAIL</label>
              <input
                placeholder="Email"
                id="email"
                className={clsx("form-field")}
                value={signInEmail}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    signIn(signInEmail, signInPassword);
                  }
                }}
                onChange={(e) => {
                  setSignInEmail(e.target.value);
                }}
              />
              <label className="form-field-label">PASSWORD</label>
              <input
                placeholder="Password"
                type="password"
                id="password"
                className={clsx("form-field")}
                value={signInPassword}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    signIn(signInEmail, signInPassword);
                  }
                }}
                onChange={(e) => {
                  setSignInPassword(e.target.value);
                }}
              />

              <button
                className="submit-button"
                title="Sign in"
                onClick={() => {
                  signIn(signInEmail, signInPassword);
                }}
              >
                SIGN IN
              </button>

              <div className="account-mode-swap-row">
                Need an account? Click{" "}
                <a
                  onClick={() => {
                    setMode(MODES.SIGN_UP);
                  }}
                >
                  here
                </a>{" "}
                to sign up.
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="header">SIGN UP</div>
            <div className="form">
              <label className="form-field-label">EMAIL</label>
              <input
                placeholder="Email"
                id="email"
                className={clsx("form-field")}
                value={signUpEmail}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    signIn(signInEmail, signInPassword);
                  }
                }}
                onChange={(e) => {
                  setSignUpEmail(e.target.value);
                }}
              />
              <label className="form-field-label">PASSWORD</label>
              <input
                placeholder="Password"
                type="password"
                id="password"
                className={clsx("form-field")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    signIn(signInEmail, signInPassword);
                  }
                }}
                value={signUpPassword}
                onChange={(e) => {
                  setSignUpPassword(e.target.value);
                }}
              />

              <button
                className="submit-button"
                title="Sign up"
                onClick={() => {
                  signUp(signUpEmail, signUpPassword);
                }}
              >
                SIGN UP
              </button>

              <div className="account-mode-swap-row">
                Already have an account? Click{" "}
                <a
                  onClick={() => {
                    setMode(MODES.SIGN_IN);
                  }}
                >
                  here
                </a>{" "}
                to sign in.
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
