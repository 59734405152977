import './App.css';
import { FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "./config/firebase";

import Application from "./pages/Application";

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Application />
    </FirebaseAppProvider>
  );
}

export default App;
