import clsx from "clsx";
import { AEDLocation } from "../../../../../types/locations";
import { useLocations } from "../../../../hooks/useLocations";
import "./LocationCard.scss";

let formatPhoneNumber = (str: string) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

export default function LocationCard({
  location,
  setActive,
  active = false,
  onShowArchiveWarningModal,
  onShowModifyModal,
}: {
  location: AEDLocation;
  setActive: React.Dispatch<React.SetStateAction<AEDLocation>>;
  active?: boolean;
  onShowArchiveWarningModal: (info: { id: string; name: string }) => void;
  onShowModifyModal: () => void;
}) {

  const { label: addressLabel } = JSON.parse(location.address);
  
  return (
    <div
      key={location.id}
      className={clsx("location-card", {
        active,
      })}
    >
      <div>
        <div
          className="location-card-header"
          onClick={() => {
            setActive(location);
          }}
        >
          {location.name}
        </div>
        <div
          className="location-card-body"
          onClick={() => {
            setActive(location);
          }}
        >
          <div>
            <img
              src="../../../../assets/images/icons/map-pin.svg"
              className="location-card-icons"
            />
            {addressLabel}
          </div>
          <div className="location-phone-number">
            <img
              src="../../../../assets/images/icons/phone.svg"
              className="location-card-icons"
            />
            <a href={`tel:${location.phoneNumber}`}>
              {formatPhoneNumber(location.phoneNumber)}
            </a>
          </div>
        </div>
        <div className="location-card-actions">
          <button
            className="location-card-action archive"
            title="Archive this location"
            onClick={() => {
              onShowArchiveWarningModal({
                id: location.id,
                name: location.name,
              });
            }}
          >
            <img
              src="../../../../assets/images/icons/archive.svg"
              className="location-card-action-icon"
            />
            ARCHIVE
          </button>
          <button
            className="location-card-action modify"
            onClick={() => {
                onShowModifyModal();
            }}
            title="Modify this location"
          >
            <img
              src="../../../../assets/images/icons/modify.svg"
              className="location-card-action-icon "
            />
            MODIFY
          </button>
        </div>
      </div>
    </div>
  );
}
