import { useEffect, useState } from "react";
import { AEDLocation } from "../../../../../types/locations";
import { DEFAULT_LOCATION } from "../../../../hooks/useLocations";
import { useRef } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import CONFIG from "../../../../config/maps.json";

export function LocationModal({
  show = false,
  onClose,
  onSubmit,
  initialLocation = DEFAULT_LOCATION,
}: {
  show: boolean;
  onClose: () => void;
  onSubmit: (location: AEDLocation) => void;
  initialLocation?: AEDLocation;
}) {
  const [location, setLocation] = useState(initialLocation);
  const [value, setValue] = useState("");

  useEffect(() => {
    setLocation(initialLocation);
  }, [initialLocation]);

  const ref = useRef();

  // const { ref } = usePlacesWidget({
  //   apiKey: CONFIG.MAPS_API_KEY,
  // });
  if (!show) {
    return <></>;
  }

  // const customStyles = {
  //   menu: (provided: any, state: any) => ({
  //     ...provided,
  //     width: state.selectProps.width,
  //     borderBottom: '1px dotted pink',
  //     color: state.selectProps.menuColor,
  //     // backgroundColor: "red",
  //     padding: 20,
  //   }),

  //   // control: (_, { selectProps: { width }}) => ({
  //   //   width: width
  //   // }),

  //   singleValue: (provided: any, state: any) => {
  //     const opacity = state.isDisabled ? 0.5 : 1;
  //     const transition = 'opacity 300ms';

  //     return { ...provided, opacity, transition };
  //   }
  // }

  return (
    <>
      <div className={`modal`}>
        <label className="modal-header">
          {initialLocation.name ? "Update Location" : "Add Location"}
        </label>
        <div className="modal-contents">
          <div className="infowrapper">

            <label className="modal-input-label">NAME </label>
            <img className="icon" src="assets/images/icons/info_icon.svg" alt="" />
            <label className="modal-input-info">An internal name for team members to identify this location.</label>
          </div>
          <input
            className="modal-input"
            value={location.name}
            onChange={(event) => {
              setLocation((current) => {
                return {
                  ...current,
                  name: event.target.value,
                };
              });
            }}
            placeholder=""
          ></input>
          <div className="infowrapper">

            <label className="modal-input-label">ADDRESS</label>
            <img className="icon" src="assets/images/icons/info_icon.svg" alt="" />
            <label className="modal-input-info">The address of the AED unit.</label>
          </div>
          <GooglePlacesAutocomplete

            apiKey={CONFIG.MAPS_API_KEY}
            selectProps={{
              className: "modal-input wrapper",
              placeholder: "",
              // value={location.address}
              classNamePrefix: "address-input",
              // inputValue: location.address ? JSON.parse(location.address).label : "",
              onChange: (locationInfo: string) => {
                const stringifiedLocationInfo = JSON.stringify(locationInfo);
                setLocation((current) => {
                  return {
                    ...current,
                    address: stringifiedLocationInfo
                  };
                })
              }
            }}
          />
          {/* <input
            className="modal-input"
            value={location.address}
            ref={ref}
            onChange={(event) => {
              setLocation((current) => {
                return {
                  ...current,
                  address: event.target.value,
                };
              });
            }}
            placeholder="Address ..."
          ></input> */}
          <div className="infowrapper">

            <label className="modal-input-label">PHONE NUMBER</label>
            <img className="icon" src="assets/images/icons/info_icon.svg" alt="" />
            <label className="modal-input-info">The phone number associated with this location.</label>
          </div>
          <input
            className="modal-input"
            value={location.phoneNumber}
            onChange={(event) => {
              setLocation((current) => {
                return {
                  ...current,
                  phoneNumber: event.target.value,
                };
              });
            }}
            placeholder=""
          ></input>
        </div>
        <div className="modal-actions">
          <div>
            <button
              title="Do you want to cancel this action?"
              onClick={() => {
                onClose();
                setLocation(DEFAULT_LOCATION);
              }}
              className="modal-action-button neutral"
            >
              Cancel

            </button>
          </div>
          <div>
            {initialLocation.name ? (
              <>
                <button
                  title="Do you want to save changes?"
                  onClick={() => {
                    onSubmit(location);
                    onClose();
                    setLocation(DEFAULT_LOCATION);
                  }}
                  className="modal-action-button right modify"
                >
                  Save Changes
                </button>
              </>
            ) : (
              <>
                <button
                  title="Do you want to add a new location?"
                  onClick={() => {
                    onClose();
                    onSubmit(location);
                    setLocation(DEFAULT_LOCATION);
                  }}
                  className="modal-action-button right create"
                >
                  Add Location
                </button>
                <button
                  title="Do you want to save changes and add another location?"
                  onClick={() => {
                    onSubmit(location);
                    setLocation(DEFAULT_LOCATION);
                  }}
                  className="modal-action-button right modify"
                >
                  Save and Add More
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="gauze"></div>
    </>
  );
}
