import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AuthProvider,
  FirestoreProvider,
  useFirebaseApp,
  useSigninCheck,
  useUser,
} from "reactfire";
import useUserInfo from "../../hooks/useUserInfo";
import Locations from "../Locations";
import SignIn from "../SignIn";

export default function Application() {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const auth = getAuth(app);
  const firestoreInstance = getFirestore(app);

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <Core />
      </FirestoreProvider>
    </AuthProvider>
  );
}

function Core() {
  const { status, data: signInCheckResult } = useSigninCheck();
  const {status: userInfoStatus} = useUser();
  const {updateUserInfo, isAdmin} = useUserInfo();
  
  if (status === "loading" || userInfoStatus === "loading") {
    return <></>
  }

  if (signInCheckResult && signInCheckResult.signedIn) {
    updateUserInfo();
  }
  
  return (
    <BrowserRouter>
      {signInCheckResult.signedIn ? (
        <Routes>
          <Route path="*" element={<Locations />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<SignIn />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}
